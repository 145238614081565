import React from 'react'
import aboutImg from "../assets/carousel2.jpg";

function About() {
  return (
    <div className='sm:flex text-center space-x-0 space-y-5 sm:space-y-0 sm:space-x-5'>
      <div className='space-y-3'>
        <div className='font-bold text-blue-900 text-2xl'>Embrace the Warmth of a Legacy Hotel</div>
        <div className='p-3'>Choose Hotel Eden Garden, Asansol for an extraordinary stay. Experience the luxurious retreat nestled in the heart of the city of Asansol. With its elegant architecture and unsullied service, we offer business and leisure travelers a tranquil escape. From well-appointed rooms to state-of-the-art amenities, Hotel Eden Garden, Asansol promises a memorable stay, blending comfort and sophistication seamlessly. Enjoy special perks when you book directly with us, including discounted rates, room upgrades (subject to availability), and loyalty rewards. Your comfort is our priority, and we want to ensure you receive the best value for your stay.</div>
      </div>
      <div className='items-end'>
        <img src={aboutImg} alt="" className='w-[3000px] h-[350px] rounded-lg'/>
      </div>
    </div>
  )
}

export default About
