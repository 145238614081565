import React from "react";
import logo from "../assets/logo.jpg";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaPhone,
  FaEnvelope,
  FaMap,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  const handleToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth'});
  };
  return (
    <div>
      <div className="bg-blue-900 text-white px-4 py-4 rounded-lg">
        <div className="sm:flex justify-between my-3">
          <div className="flex justify-between sm:flex-col">
            <div className="bg-white py-4 pl-7 pr-7 sm:pr-0 rounded-lg ">
              <img
                src={logo}
                alt="Logo"
                className="h-10 sm:h-16 w-12 sm:w-20"
              />
            </div>
            <div className="my-4">
              <div className="flex items-center gap-x-2 text-3xl">
                <div>
                  <FaFacebook className="" />
                </div>
                <div>
                  <FaTwitter className="" />
                </div>
                <div>
                  <FaInstagram className="" />
                </div>
                <div>
                  <FaWhatsapp className="" />
                </div>
              </div>
            </div>
          </div>
          <div className="my-5 sm:my-0 sm:text-left">
            <div className="text-xl font-bold mb-2">CONTACT US</div>
            <div className="space-y-2">
              <a
                href="tel:917319277077"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaPhone className="rotate-90 text-white" />
                </div>
                <div>(+91) 7319277077</div>
              </a>
              <a
                href="mailto:reservations@edengardenasansol.com"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaEnvelope className="text-white" />
                </div>
                <div>reservations@edengardenasansol.com</div>
              </a>
              <a
                href="https://maps.app.goo.gl/hXnEJSpLChtqSEnn7"
                className="flex items-center space-x-2 space-y-5 sm:space-y-0"
              >
                <div>
                  <FaMap className="text-white" />
                </div>
                <div>
                  Near Bhagat Singh More, Senraleigh Road, Asansol-713304
                </div>
              </a>
            </div>
          </div>
          <div className="my-5 sm:my-0">
            <div className="text-xl font-bold mb-2">NEW LATEST</div>
            <div>Get the latest updates and offers.</div>
            <div className="flex items-center my-2">
              {/* <label>
                <input
                  type="text"
                  className="rounded-lg p-3 text-blue-900"
                  placeholder="Email"
                />
              </label>
              <button className="mx-1">
                <FaTelegram className="size-10 bg-white text-blue-900 rounded-lg px-1 h-11" />
              </button> */}
              <Link
                to="/contact"
                className="font-bold underline"
                onClick={handleToTop}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div className="sm:flex justify-between my-2">
          <div className="sm:flex sm:space-x-16 space-y-3 sm:space-y-0 font-semibold">
            <div>Terms & Conditions</div>
            <div>Privacy Policies</div>
          </div>
          <div className="my-4 sm:my-0">
            Copyright © 2024 All rights reserved
          </div>
          <div>
            Made & Maintained by{" "}
            <span className="text-blue-300 underline mx-2">
              <a
                href="https://www.bestman.in/"
                rel="noreferrer"
                target="_blank"
              >
                Bestman Exceed Software LLP
              </a>
            </span>
            |
            <span className="text-blue-300 underline mx-2">
              <a href="https://subhadipdas4521.netlify.app/">Subhadip Das</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;