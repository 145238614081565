import React from "react";
import { Link } from "react-router-dom";
import "primeicons/primeicons.css";
import miniBar from "../assets/minibar.png";
import ac from "../assets/air-conditioner.svg";
import dryer from "../assets/hair-dryer-on.svg";
import dressingRoom from "../assets/dressing-room.svg";
import iron from "../assets/iron.svg";
import mirror from "../assets/mirror.svg";
import desk from "../assets/desk.svg";
import bottol from "../assets/water-bottle.svg";
import living from "../assets/living-room.svg";
import {
  FaCoffee,
  FaKey,
  FaNewspaper,
  FaShower,
  FaTv,
  FaWifi,
} from "react-icons/fa";

function Rooms({
  img,
  name,
  clName1,
  clName2,
  clName3,
  clName4,
  clName5,
  clName6,
  clName7,
  clName8,
  clName9,
  clName10,
  clName11,
  clName12,
  clName13,
  clName14,
  clName15,
  desc,
  seeMore,
}) {
  const handleToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="bg-gray-200 p-3 rounded-lg">
        <div>
          <img src={img} alt="" className="rounded-lg" />
        </div>
        <div className="my-4">
          <div className="font-semibold text-xl text-center">{name}</div>
          <div className="text-4xl grid grid-cols-4 sm:grid-cols-8 space-x-3 space-y-2 justify-center items-center mx-auto my-4">
            <div className={clName1}>
              <label title="Free Wifi" htmlFor="">
                <FaWifi className="my-2 mx-4" />
              </label>
            </div>
            <div className={clName2}>
              <label title="Flat Screen Tv" htmlFor="">
                <FaTv />
              </label>
            </div>
            <div className={clName3}>
              <label title="Coffee and Tea Facilities" htmlFor="">
                <FaCoffee />
              </label>
            </div>
            <div className={clName4}>
              <label title="Minibar" htmlFor="">
                <img src={miniBar} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className={clName5}>
              <label title="Hot Water Shower" htmlFor="">
                <FaShower />
              </label>
            </div>
            <div className={clName6}>
              <label title="Air Conditioner" htmlFor="">
                <img src={ac} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className={clName7}>
              <label title="Keyless Entry" htmlFor="">
                <FaKey />
              </label>
            </div>
            <div className={clName8}>
              <label title="Hair Dryer" htmlFor="">
                <img src={dryer} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className={clName9}>
              <label title="Dressing Unit" htmlFor="">
                <img src={dressingRoom} alt="" className="h-7 w-8" />
              </label>
            </div>
            <div className={clName10}>
              <label title="Iron and Ironing Bed" htmlFor="">
                <img src={iron} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className={clName11}>
              <label title="Daily Newspaper/ Magazine" htmlFor="">
                <FaNewspaper />
              </label>
            </div>
            <div className={clName12}>
              <label title="Full Length Mirror" htmlFor="">
                <img src={mirror} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className={clName13}>
              <label title="Work Desk" htmlFor="">
                <img src={desk} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className={clName14}>
              <label title="Separate Living Room" htmlFor="">
                <img src={living} alt="" className="h-8 w-9" />
              </label>
            </div>
            <div className={clName15}>
              <label title="Complimentary Bottled Water" htmlFor="">
                <img src={bottol} alt="" className="h-8 w-9" />
              </label>
            </div>
          </div>
          <div className="">
            <div>
              {desc}
              <div className={seeMore}>
                ...{" "}
                <Link to="/rooms" className="text-blue-600 underline" onClick={handleToTop}>
                  See more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rooms;