import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Dropdown() {
    const [dropdown, setDropdown]= useState("hidden sm:block");
    const handleBar= ()=>{
        if(dropdown==="hidden sm:block") setDropdown("")
        if(dropdown==="") {
            setDropdown("hidden sm:block")
        }
    }
    const  handleClose= ()=>{
        if(dropdown==="") setDropdown("hidden sm:block")
    }
  return (
    <div>
      <Menu as="div" className="relative inline-block">
      <div className='-mx-2' onClick={handleBar}>
        <Menu.Button className="inline-flex w-full items-center hover:underline justify-center px-3 py-2 text-sm sm:text-lg font-semibold text-blue-900 ">
          Banquet
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-blue-900" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className={dropdown}>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/conference"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={handleClose}
                >
                  Conference Hall
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/club"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={handleClose}
                >
                  Club House
                </Link>
              )}
            </Menu.Item>
            
            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>

    </div>
  )
}

export default Dropdown
