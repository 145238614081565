import React from 'react'
import About from './About'
import UpperNav from './UpperNav'
import CarouselDefault from './CarouselDefault'
import { FaHandPointRight } from 'react-icons/fa'
import Footer from '../components/Footer'




function AboutPage() {
    const data= [
        {
            img:"photo1.jpg"
        },
        {
            img:"photo2.jpg"
        },
        {
            img:"photo3.jpg"
        },
        {
            img:"Restaurant.jpg"
        }
    ];
  return (
    <div>
        <div className='underline font-bold text-3xl text-blue-900 text-center my-5'>
            About Us
        </div>
        <div className='mx-5 my-10'>
            <About/>
        </div>
        <div className='mx-5 my-20 text-center'>
            <div className='text-3xl text-blue-900 font-semibold'>Relax In Total Comfort</div>
            <div>Allow us to redefine your notion of hospitality as we present a haven meticulously crafted to ensure your comfort and relaxation. Designed to make you feel at ease, each of our spacious rooms and suites includes free Wi-Fi and complimentary turndown service. Order from the room service menu to enjoy a quiet night in your room, or upgrade to a suite to enjoy happy hours in the Living Room.</div>
            <div className='my-5'>
                    <div className='sm:flex sm:space-x-3 space-y-3 sm:space-y-0 justify-around'>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold hover:text-lg flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>10% Off On Accommodation.</div></div>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold hover:text-lg flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>Free Wifi.</div></div>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold hover:text-lg flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>Different Type of F&B</div></div>
                    </div>
                </div>
        </div>
        <div className='mx-5 my-20 text-center'>
            <div className='text-3xl text-blue-900 font-semibold'>Look Around</div>
            <div className='my-10 sm:px-20 sm:pb-20'>
                <CarouselDefault data={data}/>
            </div>
        </div>
        <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Eden Garden Asansol</h3>
            </div>
            <UpperNav/>
        </div>
    </div>
  )
}

export default AboutPage
