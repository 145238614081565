import React from 'react'
import { FaHandPointRight } from 'react-icons/fa'
import Footer from './Footer'
import UpperNav from './UpperNav'

function Conference() {
  return (
    <div>
      <div>
        <div className='underline font-bold text-3xl text-blue-900 text-center my-5'>
            Conference Hall
        </div>
        <div className='text-center'>
            <div className='text-xl'>Elevate Your Events at ThinkTank- The Premier Conference Destination!</div>
            <div className='text-blue-800 mx-20'>As the preferred conference destination, we offer a state-of-the-art venue designed to accommodate a wide range of events, from corporate conferences and seminars to workshops and product launches.</div>
        </div>
        <div className='mx-10 my-10 space-y-4'>
        <div className='text-3xl font-bold text-blue-900 text-center'>What We offer:</div>
        <hr />
        <div className='sm:grid grid-cols-3 text-center space-x-5 space-y-4 sm:space-y-0'>
            <div>
                <div className='font-bold'>Cutting-Edge Technology:</div>
                <div>Our conference hall is equipped with the latest audiovisual technology, high-speed Wi-Fi, and advanced presentation tools. Seamless
connectivity ensures that your message is delivered with precision and impact.</div>
            </div>
            <div>
                <div className='font-bold'>Flexible Spaces:</div>
                <div> ThinkTank offers versatile spaces that can be configured to suit
your event requirements. Whether you're hosting a conference or an intimate
seminar, our flexible layouts and modular furniture adapt to your needs.</div>
            </div>
            <div>
                <div className='font-bold'>Professional Event Support:</div>
                <div>Our dedicated event management team is committed
to ensuring the success of your event. From planning to execution, our experienced
staff is on hand to assist with logistics, technical support, and any special
requirements.</div>
            </div>
        </div>
        <div className='sm:grid grid-cols-4 text-center space-y-4 sm:space-y-0'>
            <div>
                <div className='font-bold'>Comfort and Style:</div>
                <div>Experience unparalleled comfort in our ergonomic seating
arrangements, designed for long conferences. The contemporary and stylish decor
sets the tone for a professional and engaging atmosphere.</div>
            </div>
            <div>
                <div className='font-bold'>Catering Excellence:</div>
                <div>Elevate your event with our exquisite catering services. Choose
from a range of customizable menus that cater to diverse preferences, ensuring your
attendees are energized and satisfied throughout the day.</div>
            </div>
            <div>
                <div className='font-bold'>Convenient Location:</div>
                <div>Thinktank at Hotel Eden Garden is strategically located,
making it easily accessible for attendees. Ample parking facilities and proximity to
public transportation add to the convenience of hosting your event with us.</div>
            </div>
            <div>
                <div className='font-bold'>Green Initiatives:</div>
                <div>We are committed to sustainability. Our conference hall is
designed with eco-friendly practices, ensuring that your event leaves a positive
impact on the environment.</div>
            </div>
        </div>
        <hr />
        </div>
        <div className='mx-5 my-20 text-center'>
            <div className='text-3xl text-blue-900 font-semibold'>Exclusive Proposition</div>
            <div className='my-5'>
                    <div className='sm:flex sm:space-x-3 space-y-3 sm:space-y-0 justify-around'>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>Early Booking Discounts: Reserve your conference hall in advance and enjoy special discounts on venue fees.</div></div>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>Complimentary Tech Package: Avail a complimentary technology package, including advanced AV equipment and technical support, for events booked during our promotional period.</div></div>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>Virtual Event Solutions: Explore our virtual event packages for hybrid conferences, seamlessly connecting attendees from around the globe.</div></div>
                    </div>
                </div>
                <div>Host your next conference at ThinkTank and experience the perfect blend of professionalism, technology, and comfort. Contact us today to schedule a tour and discuss how we can make your event a resounding success!</div>
        </div>
        <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Eden Garden Asansol</h3>
            </div>
            <UpperNav/>
        </div>
      </div>
    </div>
  )
}

export default Conference
