import React from 'react'
import Footer from './Footer'
import UpperNav from './UpperNav'
import { FaHandPointRight } from 'react-icons/fa'

function Club() {
  return (
    <div>
      <div>
        <div className='underline font-bold text-3xl text-blue-900 text-center my-5'>
            Club Hall
        </div>
        <div className='text-center'>
            <div className='text-xl'>The Club House-Your Perfect Venue for Celebrations, Grandeur, and Memorable Moments</div>
            <div className='text-blue-800 mx-20'>The Club House Banquets invites you to experience the epitome of opulence and exceptional service. Our banquet halls are designed to transform your special occasions into timeless memories. From weddings and anniversaries to corporate galas and social events, we are here to make every moment grand.</div>
        </div>
        <div className='mx-10 my-10 space-y-4'>
        <div className='text-3xl font-bold text-blue-900 text-center'>What We offer:</div>
        <hr />
        <div className='sm:grid grid-cols-3 text-center space-x-5 space-y-4 sm:space-y-0'>
            <div>
                <div className='font-bold'>Elegant Ambiance:</div>
                <div>Step into a world of sophistication and charm. Our banquet halls boast exquisite decor, luxurious furnishings, and ambient lighting, creating the perfect backdrop for your celebrations.</div>
            </div>
            <div>
                <div className='font-bold'>State-of-the-Art Facilities:</div>
                <div> ThinkTank offers versatile spaces that can be configured to suit
your event requirements. Whether you're hosting a conference or an intimate
seminar, our flexible layouts and modular furniture adapt to your needs.</div>
            </div>
            <div>
                <div className='font-bold'>Culinary Delights:</div>
                <div>Indulge in a gastronomic journey crafted by our expert chefs. Our customizable menus feature a diverse array of culinary delights, from exquisite appetizers to sumptuous main courses and decadent desserts.</div>
            </div>
        </div>
        <div className='sm:grid grid-cols-3 text-center space-y-4 sm:space-y-0'>
            <div>
                <div className='font-bold'>Professional Event Management:</div>
                <div>Our dedicated event planning team is committed to bringing your vision to life. From initial consultation to the day of the event, we provide personalized service and meticulous attention to detail.</div>
            </div>
            <div>
                <div className='font-bold'>Flexible Packages:</div>
                <div>Tailor your event with our flexible packages that cater to your specific needs and preferences. We offer a range of options, from all-inclusive packages to customizable solutions, ensuring your celebration aligns with your expectations.</div>
            </div>
            <div>
                <div className='font-bold'>Free Parking:</div>
                <div>Make a grand entrance with our convenient parking services. Your guests will appreciate the ease and comfort of arriving at our venue.</div>
            </div>
        </div>
        <hr />
        </div>
        <div className='mx-5 my-20 text-center'>
            <div className='text-3xl text-blue-900 font-semibold'>Exclusive Proposition</div>
            <div className='my-5'>
                    <div className='sm:flex sm:space-x-3 space-y-3 sm:space-y-0 justify-around'>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>Early Bird Discounts: Book your celebration in advance and enjoy exclusive discounts on venue fees.</div></div>
                        <div className='bg-blue-200 rounded-lg px-10 py-2 font-semibold flex items-center space-x-2'><span><FaHandPointRight/></span><div className='text-center'>Bliss Package:
Explore our special packages, including decorations, catering, and more, to make your big day truly magical.</div></div>
                    </div>
                </div>
                <div>Host your next celebration at The Club House Banquets and let us turn your dreams into a grand reality. Contact us today to schedule a tour and start planning your unforgettable event!</div>
        </div>
        <div className='mx-5 my-10'>
          <Footer/>
        </div>
        <div className='sm:hidden sticky bottom-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Eden Garden Asansol</h3>
            </div>
            <UpperNav/>
        </div>
      </div>
    </div>
  )
}

export default Club
