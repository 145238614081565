import React from "react";
import UpperNav from "./UpperNav";
import Footer from "../components/Footer";
import { FaEnvelope, FaMap, FaPhone } from "react-icons/fa";

function Contact() {
  return (
    <div>
      <div className="underline font-bold text-3xl text-blue-900 text-center my-5">
        Contact Us
      </div>
      <div className="sm:flex justify-around">
        <div>
          <div className="mx-5 text-lg space-y-2">
            <div>
              <a
                href="https://maps.app.goo.gl/hXnEJSpLChtqSEnn7"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaMap className="text-blue-900" />
                </div>
                <div className="mt-6 sm:mt-0">
                  Near Bhagat Singh More, Senraleigh Road, Asansol - 713304
                </div>
              </a>
            </div>
            <div>
              <a
                href="mailto:reservations@edengardenasansol.com"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaEnvelope className="text-blue-900" />
                </div>
                <div>reservations@edengardenasansol.com</div>
              </a>
            </div>
            <div>
              <a
                href="tel:917319277077"
                className="flex items-center space-x-2"
              >
                <div>
                  <FaPhone className="rotate-90 text-blue-900" />
                </div>
                <div>(+91) 7319277077</div>
              </a>
            </div>
          </div>
        </div>
        <div className="mx-10 my-5 sm:my-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.4564368488236!2d86.94970057398703!3d23.69538909088861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f71fecd1b6a7cf%3A0x51499e50c97c1bd3!2sHotel%20Eden%20Garden!5e0!3m2!1sen!2sin!4v1690527581186!5m2!1sen!2sin"
            className="rounded-lg w-[85vw] sm:w-[50vw] sm:h-[50vh] border-0"
            title="map"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="mx-5 my-10">
        <Footer />
      </div>
      <div className="sm:hidden sticky bottom-0 bg-white">
        <div className="mt-5">
          <h3 className="uppercase font-bold text-center">
            Hotel Eden Garden Asansol
          </h3>
        </div>
        <UpperNav />
      </div>
    </div>
  );
}

export default Contact;