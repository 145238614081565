import React, { useState } from "react";
import logo from "../assets/logo.jpg";
import { FaBars } from "react-icons/fa";
import UpperNav from "./UpperNav";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";

function Navbar() {
  const [bar, setBar] = useState("hidden sm:block");
  const handleBar = () => {
    if (bar === "hidden sm:block") setBar("");
    if (bar === "") setBar("hidden sm:block");
  };
  const handleClose = () => {
    if (bar === "") setBar("hidden sm:block");
  };
  return (
    <div>
      <div className="hidden sm:block">
        <UpperNav />
      </div>
      <div className="my-3 mx-5 sm:flex items-center justify-between">
        <div className="flex items-center justify-between gap-x-5">
          <div>
            <img src={logo} alt="" className="h-10 sm:h-16 w-12 sm:w-20" />
          </div>
          <div
            id="hotelName"
            className="text-xl sm:text-2xl font-bold text-blue-900"
          >
            HOTEL EDEN GARDEN
          </div>
          <div className="sm:hidden" onClick={handleBar}>
            <FaBars className="text-blue-900" />
          </div>
        </div>
        <div className={bar}>
          <ul className="flex flex-wrap justify-center items-center gap-x-4 sm:gap-x-6 text-sm sm:text-lg font-semibold my-4">
            <li
              className="cursor-pointer hover:underline text-blue-900"
              onClick={handleClose}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className="cursor-pointer hover:underline text-blue-900"
              onClick={handleClose}
            >
              <Link to="/about">About</Link>
            </li>
            <li
              className="cursor-pointer hover:underline text-blue-900"
              onClick={handleClose}
            >
              <Link to="/rooms">Rooms</Link>
            </li>
            <li className="cursor-pointer hover:underline text-blue-900">
              <Dropdown />
            </li>
            <li
              className="cursor-pointer hover:underline text-blue-900"
              onClick={handleClose}
            >
              <Link to="/restaurant">Restaurant</Link>
            </li>
            <li
              className="cursor-pointer hover:underline text-blue-900"
              onClick={handleClose}
            >
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;