import React from 'react'
import Navbar from '../components/Navbar'
import { BrowserRouter,  Route,  Routes } from 'react-router-dom'
import Body from '../components/Body'
import AboutPage from '../components/AboutPage'
import RoomPage from '../components/RoomPage'
import Restaurant from '../components/Restaurant'
import Contact from '../components/Contact'
import Conference from '../components/Conference'
import Club from '../components/Club'
import PageNotFound from '../components/PageNotFound'


function Page() {
  return (
      <BrowserRouter>
        <Navbar/> 
        <Routes>
          <Route exact path="/" element={<Body/>}/>
          <Route exact path="/about" element={<AboutPage/>}/>
          <Route exact path="/rooms" element={<RoomPage/>}/>
          <Route exact path="/restaurant" element={<Restaurant/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route exact path="/conference" element={<Conference/>}/>
          <Route exact path="/club" element={<Club/>}/>
          <Route path="/*" element={<PageNotFound/>}/>
        </Routes>
      </BrowserRouter>
  )
}

export default Page
