import React from "react";
import Rooms from "./Rooms";

import room1 from "../assets/room1.jpg";
import room2 from "../assets/room2.jpg";
import room3 from "../assets/room3.jpg";
import room4 from "../assets/room4.jpg";
import UpperNav from "./UpperNav";
import Footer from "../components/Footer";

function RoomPage() {
  return (
    <div>
      <div className="underline font-bold text-3xl text-blue-900 text-center my-10">
        Rooms
      </div>
      <div className="sm:flex sm:space-x-3 space-y-2 sm:space-y-0 mx-5 my-5">
        <Rooms
          img={room1}
          clName10={"hidden"}
          clName11={"hidden"}
          clName12={"hidden"}
          clName13={"hidden"}
          clName14={"hidden"}
          clName15={"hidden"}
          seeMore={"hidden"}
          name={"Deluxe Room"}
          desc={
            "Thoughtfully designed interiors create a welcoming ambiance, ensuring a stay that feels both expansive and intimate. Choose between one king bed or two twin beds to best suit your needs. Perfect for meditation, these windowless rooms offer you complete tranquillity."
          }
        />
        <Rooms
          img={room2}
          clName9={"hidden"}
          clName11={"hidden"}
          clName13={"hidden"}
          clName14={"hidden"}
          clName15={"hidden"}
          seeMore={"hidden"}
          name={"Premier Room"}
          desc={
            "Enjoy modern comforts in our Premier Room. The strategically placed windows invite natural light to illuminate every corner of the room and woody textured floors add a blended touch of style and warmth. If you need a refreshing drink or a caffeine boost, take advantage of the complimentary bottled water, minibar, and coffee facilities in your room. Unwind with the multimedia hub with HDMI, Bluetooth connectivity, a data port, and a smart flat-screen TV. For your utmost convenience, the room is equipped with free Wi-Fi."
          }
        />
      </div>
      <div className="sm:flex sm:space-x-3 space-y-2 sm:space-y-0 mx-5">
        <Rooms
          img={room3}
          clName4={"hidden"}
          clName8={"hidden"}
          clName9={"hidden"}
          clName10={"hidden"}
          clName11={"hidden"}
          clName12={"hidden"}
          clName13={"hidden"}
          clName14={"hidden"}
          clName15={"hidden"}
          seeMore={"hidden"}
          name={"Standard Room"}
          desc={
            "Indulge in the epitome of comfort and sophistication with our Suite Rooms, where every detail is meticulously crafted to provide you with an unforgettable stay. Check into your suite and enjoy a special treat as part of our premium turndown service includes chocolates/ pralines that you can enjoy with a drink from the complimentary minibar. Use the multimedia hub to connect your laptop to the flat screen via Bluetooth so you can stream your favorite shows or music before bedtime. Pamper yourself in our indulgent bathrooms featuring premium fixtures, and designer toiletries."
          }
        />
        <Rooms
          img={room4}
          clName9={"hidden"}
          seeMore={"hidden"}
          name={"Suite Room"}
          desc={
            "Welcome to our Suite Room! We're thrilled to welcome you to a world of luxury and comfort. Indulge in the lavish amenities and elegant design of our premium suite accommodations. Our dedicated team is here to ensure your every need is met, providing attentive service throughout your stay. Relax and unwind in the spacious and stylish surroundings of our suite room. Experience the epitome of sophistication and relaxation during your time with us. Your stay in our Suite Room is guaranteed to be a memorable and delightful experience."
          }
        />
      </div>
      <div className="mx-5 my-5">
        <hr />
        <div className="my-5 text-xl text-center text-blue-500">
          Drift into a peaceful slumber on our premium king-size beds adorned
          with the finest linens and plump pillows.
        </div>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0 justify-around">
          <div className="bg-blue-200 rounded-lg px-10 py-2 text-center font-semibold hover:text-xl">
            VIP food and beverages
          </div>
          <div className="bg-blue-200 rounded-lg px-10 py-2 text-center font-semibold hover:text-xl">
            Turndown service
          </div>
          <div className="bg-blue-200 rounded-lg px-10 py-2 text-center font-semibold hover:text-xl">
            Pick up and drop services
          </div>
        </div>
      </div>
      <div className="mx-5 my-10">
        <Footer />
      </div>
      <div className="sm:hidden sticky bottom-0 bg-white">
        <div className="mt-5">
          <h3 className="uppercase font-bold text-center">
            Hotel Eden Garden Asansol
          </h3>
        </div>
        <UpperNav />
      </div>
    </div>
  );
}

export default RoomPage;