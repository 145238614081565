import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function CarouselDefault({data}) {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 2,
        slidesToScroll: 1,
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        initialSlide: 0,
        responsive: [
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        }
        ]
      };

  return (
    <>
        <div>
            <Slider {...settings}>
                {data.map((d, index)=>
                    <div key={index} className="w-3/4 m-auto">
                        <img src={require(`../assets/${d.img}`)} alt="" className="rounded-lg my-2" />
                    </div>
                )}
            </Slider>
        </div>
    </>
  );
}


export default CarouselDefault