import React from 'react';
import homeImg from "../assets/carousel1.jpg"
import UpperNav from './UpperNav';
import About from './About';
import Rooms from './Rooms';
import Footer from '../components/Footer'

import room1 from "../assets/room1.jpg"
import room2 from "../assets/room2.jpg"
import room3 from "../assets/room3.jpg"
import room4 from "../assets/room4.jpg"

function Body() {
  return (
    <div>
      <div className='mx-5 my-5'>
        <img src={homeImg} alt="" className='h-[58vh] sm:h-[700px] w-full rounded-lg'/>
      </div>
        <div className='sm:hidden sticky top-0 bg-white'>
            <div className='mt-5'>
                <h3 className='uppercase font-bold text-center'>Hotel Eden Garden Asansol</h3>
            </div>
            <UpperNav/>
        </div>
      <div className='mx-5 my-10'>
        <About/>
      </div>
      <div className='mx-5 my-10'>
        <div className='underline font-bold text-3xl text-blue-900 text-center my-5'>Rooms</div>
        <div className='sm:flex sm:space-x-3 space-y-2 sm:space-y-0 my-5'>
            <Rooms img={room1} clName10={"hidden"} clName11={"hidden"} clName12={"hidden"} clName13={"hidden"} clName14={"hidden"} clName15={"hidden"} name={"Deluxe Room"} desc={"Upgrade your stay with spacious and sumptuously appointed beds, carefully curated furnishings, and contemporary design elements adorned with the finest linens, promising a night of sublime relaxation."}/>
            <Rooms img={room2} clName9={"hidden"} clName11={"hidden"} clName13={"hidden"} clName14={"hidden"} clName15={"hidden"} name={"Premier Room"} desc={"Distinctive ambiance created by elegant furnishings, contemporary design, cozy seating area, lavish bathroom, featuring premium fixtures, a rejuvenating rain shower, and luxurious bath amenities. The strategically placed windows invite natural light to illuminate every corner of the room."}/>   
        </div>
        <div className='sm:flex sm:space-x-3 space-y-2 sm:space-y-0'>
            <Rooms img={room3} clName4={"hidden"} clName8={"hidden"} clName9={"hidden"} clName10={"hidden"} clName11={"hidden"} clName12={"hidden"} clName13={"hidden"} clName14={"hidden"} clName15={"hidden"} name={"Standard Room"} desc={"Modern and stylish, these rooms offer comfortable beds with plush bedding, and other amenities ensuring a restful night sleep after a day of exploration or work."}/>
            <Rooms img={room4} clName9={"hidden"} name={"Suite Room"} desc={""}/>
        </div>
      </div>
      <div className='mx-5 my-10'>
          <Footer/>
        </div>
    </div>
  )
}

export default Body